.ql-toolbar,
.ql-container {
	background: white;
}

.ql-editor p:not(:first-child),
.ql-editor ol:not(:first-child),
.ql-editor ul:not(:first-child) {
	margin-top: 1em;
}

/* ---- WRAPPING PLACEHOLDER FIX ---- */
/* https://github.com/quilljs/quill/issues/2413#issuecomment-548576853 */
.ql-editor.ql-blank::before {
	content: none;
}

.ql-editor.ql-blank::after {
	color: rgba(0, 0, 0, 0.6);
	content: attr(data-placeholder);
	display: block;
	font-style: italic;
	margin-top: -1.42em;
	min-height: 1em;
	pointer-events: none;
}

/* -- END WRAPPING PLACEHOLDER FIX -- */
